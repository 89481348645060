<template>
  <div class="page-wrap phone-robot">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">智慧园区解决方案</div>
          <div class="desc">以信息技术为手段、智慧应用为支撑，全面整合园区内外的资源，使园区管理服务等更高效便捷，实现基础设施网络化、管理信息化、功能服务精准化和产业发展智能化，全面提升园区信息化管理水平。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 背景及痛点 -->
    <div class="section bg-white pain">
      <div class="title">背景及痛点</div>
      <div class="content">
        <div class="list">
          <div class="item item-1">
            <div class="title">产业运营管理弱，导致产业 低效运行</div>
            <div class="desc">重建设，轻运营是很多产业园区面临的问题。当前，众多开发园区在建设发展过程中注重招商引资和基础配套设施建设，却忽视了园区产业的运营管理与运营服务，服务配套资源较少，是当前多数开发园区发展面临低效困境。</div>
          </div>
          <div class="item item-2">
            <div class="title">缺乏统一数字智能系统作为运营管理抓手</div>
            <div class="desc">园区各系统存在信息烟囱，信息难以全局掌控，难以实现对整个园区企业的信息数据系统联动。亟需一套完整的智慧运营系统为园区开辟可行的运营模式、管理规范和盈利空间。</div>
          </div>
          <div class="item item-3">
            <div class="title">缺乏成熟的商业模式与智慧服务体系</div>
            <div class="desc">传统产业园区，园区综合性的管理平台和企业服务平台缺失 。园区配套服务、增值服务等产业链缺失，导致园区自我造血功能差。园区对客户信息的管理较弱，无法形成园区产业氛围，提升园区吸引力。</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 解决思路 -->
    <div class="section plan">
      <div class="title">解决思路</div>
      <div class="sub-title">构建园区运营、物业管理、企业服务一体化的园区管理新模式，提供安全、便捷、高效的工作环境！</div>
      <div class="content">
        <div class="detail">
          <div class="left">
            <div class="bg-plan"></div>
          </div>
          <div class="right">
            <div class="list">
              <div class="item" v-for="item in planList" :key="item.name">
                <div class="name">{{item.name}}</div>
                <div class="content">{{item.content}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 总体架构 -->
    <div class="section bg-white arc">
      <div class="title">总体架构</div>
      <div class="content">
        <div class="bg-arc"></div>
      </div>
    </div>

    <!-- 核心应用 -->
    <div class="section scene">
      <div class="title">核心应用</div>
      <div class="sub-title">基于未来智慧园区的发展理念，打造：两个中心，四个平台，五大应用体系（2+4+5）</div>
      <div class="content">
        <div class="bg-scene"></div>
      </div>
    </div>

    <!-- 应用价值 -->
    <div class="section bg-white scene-value">
      <div class="title">应用价值</div>
      <div class="content">
        <div class="value-list">
          <div class="item" v-for="item in valueList" :key="item.name">
            <div class="image">
              <img :src="getImage(item)" :alt="item.name" />
            </div>
            <div class="title">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      valueList: [
        {
          name: '标准化、规范化管理流程，减少人员15%以上',
          icon: 'i1.png'
        },
        {
          name: '物联数据联动与分析，降低运营成本10%以上',
          icon: 'i2.png'
        },
        {
          name: '节能策略，降低能耗10%以上',
          icon: 'i3.png'
        },
        {
          name: '智慧报警、异常感知，多端联动，快速警报',
          icon: 'i4.png'
        },
        {
          name: '业务场景闭环，协同各子系统，深挖场景增值服务',
          icon: 'i5.png'
        },
        {
          name: '形象展示窗口，体现先进管理理念，提升企业形象',
          icon: 'i6.png'
        },
        {
          name: '管理人员辅助决策，快速掌控整体态势',
          icon: 'i7.png'
        },
        {
          name: '数据互联互通，打破信息孤岛，提升用户体验',
          icon: 'i8.png'
        }
      ],
      planList: [
        {
          name: '数字园区',
          content: `“统一标准、数据集中、应用集成、硬件集群”，对信息资源进行整合与优化，构建一体化数字园区平台`,
        },
        {
          name: '绿色园区',
          content: `通过软硬件一体化建设，实现能源管控与高效使用，打造绿色园区文化，实现园区的节能与环保`,
        },
        {
          name: '平安园区',
          content: `人防、技防、物防相结合，构建事前智能感知预警、事中实时发现与追踪、事后综合分析的管理模式，实现园区安全和谐`,
        },
        {
          name: '感知园区',
          content: `以物联网为基础，基于可视化的应用服务，对园区的各类物联终端进行集成与监控，实现园区的智能全感知`,
        },
        {
          name: '互联园区',
          content: `基于多源网络融合与移动互联网，将企业服务、员工服务、生活服务进行无缝链接，实现园区的智慧办公与智慧生活的目标`,
        }
      ]
    }
  },
  methods: {
    getImage(item) {
      return require(`@/assets/community/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
